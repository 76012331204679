export const ALLOWED_ORIGINS = [
    'http://localhost:3000',
    'http://localhost:3000/',
    'https://meii.pack.wtf',
    'https://meii.pack.wtf/',
    'https://pack.wtf',
    'https://pack.wtf/',
    'http://localhost:7536',
    'http://localhost:7536/',
    'https://item.wtf',
    'https://item.wtf/',
    'https://tag.pack.wtf',
    'https://tag.pack.wtf/'
];
