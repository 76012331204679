import React, { useEffect, useState } from 'react';
import styles from './TitleHeader.module.css';
import {
    BluetoothConnected,
    ChevronsLeftRightEllipsis,
    KeyRound,
    Sparkles
} from 'lucide-react';
import { motion, useAnimation } from 'framer-motion';

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const TitleHeader: React.FC<{
    authenticated: boolean;
    username?: string;
    avatar?: string;
    isAuthenticating: boolean;
}> = ({ authenticated, username, avatar, isAuthenticating }) => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [isHovered, setIsHovered] = useState(false);
    const usernameControls = useAnimation();
    const connectedControls = useAnimation();

    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        // Animate username every 30 seconds
        const animateUsername = async () => {
            // await sleep(3000);
            await usernameControls.start({
                x: [20, 0],
                opacity: [0, 1],
                transition: { duration: 0.5 }
            });
            await new Promise((resolve) => setTimeout(resolve, 8000));
            await usernameControls.start({
                x: [0, 20],
                opacity: [1, 0],
                transition: { duration: 0.5 }
            });
        };

        const interval = setInterval(animateUsername, 14500);

        // Animate username every 30 seconds
        const animateConnected = async () => {
            await connectedControls.start({
                y: [-100, -70],
                opacity: [0, 1],
                transition: { duration: 0.5 }
            });
            await new Promise((resolve) => setTimeout(resolve, 8000));
            await connectedControls.start({
                y: [-70, -100],
                opacity: [1, 0],
                transition: { duration: 0.5 }
            });
        };

        const interval2 = setInterval(animateConnected, 30000);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
            clearInterval(interval);
            clearInterval(interval2);
        };
    }, [usernameControls]);

    // Handle hover animation
    useEffect(() => {
        if (isHovered) {
            usernameControls.start({
                x: 0,
                opacity: 1,
                transition: { duration: 0.5 }
            });
        } else {
            usernameControls.start({
                x: 20,
                opacity: 0,
                transition: { duration: 0.5 }
            });
        }
    }, [isHovered, usernameControls]);

    useEffect(() => {
        console.log('Checking auth');
        console.log(username);
        console.log(avatar);
        console.log(isAuthenticating);
    }, [username, avatar, isAuthenticating]);

    return (
        <div>
            <div className={styles.headerWrapper}>
                <div className={styles.headerRight}>
                    <motion.button
                        onHoverStart={() => setIsHovered(true)}
                        onHoverEnd={() => setIsHovered(false)}
                        className={styles.miiButton}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}>
                        <KeyRound className={styles.headerIcon} />
                        <span>Auth</span>
                    </motion.button>
                </div>

                <div className={styles.headerLeft}>
                    {avatar &&
                        username &&
                        !isAuthenticating &&
                        authenticated && (
                            <>
                                <motion.div
                                    className={styles.usernameWrapper}
                                    animate={usernameControls}
                                    initial={{ x: 20, opacity: 0 }}>
                                    <span className={styles.username}>
                                        {username}
                                    </span>
                                </motion.div>
                                <motion.div
                                    className={styles.profileSection}
                                    onHoverStart={() => setIsHovered(true)}
                                    onHoverEnd={() => setIsHovered(false)}>
                                    <motion.div
                                        className={styles.profileImageWrapper}
                                        onClick={() => handleProfileClick()}>
                                        <div className={styles.profileBorder} />
                                        <img
                                            src={
                                                avatar || '/default-avatar.png'
                                            }
                                            alt={username}
                                            className={styles.profileImage}
                                        />
                                        <motion.div
                                            className={styles.sparkleWrapper}
                                            animate={{ rotate: 360 }}
                                            transition={{
                                                duration: 8,
                                                repeat: Infinity,
                                                ease: 'linear'
                                            }}>
                                            <Sparkles
                                                className={styles.sparkleIcon}
                                                size={14}
                                            />
                                        </motion.div>
                                    </motion.div>
                                </motion.div>
                            </>
                        )}
                </div>
            </div>
            <div className={styles.middle}>
                <motion.button
                    className={`${styles.statusButton} ${
                        isOnline ? styles.online : styles.offline
                    }`}
                    // whileHover={{ scale: 1.05 }}
                    // whileTap={{ scale: 0.95 }}
                    animate={connectedControls}
                    initial={{ y: -100, opacity: 0 }}>
                    <motion.div
                        className={styles.statusPulse}
                        animate={{
                            scale: [1, 1.2, 1],
                            opacity: [0.7, 0.3, 0.7]
                        }}
                        transition={{ duration: 2, repeat: Infinity }}>
                        <ChevronsLeftRightEllipsis strokeWidth={3} />
                    </motion.div>
                    <span className={styles.connectedWrapper}>
                        {isOnline ? 'Connected' : 'Disconnected'}
                    </span>
                </motion.button>
            </div>
        </div>
    );
};

export default TitleHeader;
